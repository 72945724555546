const fullUrl = window.location.href;
const pageName = fullUrl.split('/').pop().split('.')[0];

if (pageName === 'contact') {
    const burgerMenu = document.querySelector('.burger-menu');
    const nav = document.querySelector('.mob-side-header');
    const y = document.querySelector('#toast');
    const contactForm = document.getElementById('send');

    if (burgerMenu && nav) {
        burgerMenu.addEventListener('click', () => {
            nav.classList.toggle('open');
        });
    }

    if (contactForm) {
        const handleSubmit = async (event) => {
            event.preventDefault();

            const formData = {
                fullName: document.getElementById('name')?.value,
                email: document.getElementById('email')?.value,
                phone: document.getElementById('phone')?.value,
                date: document.getElementById('date')?.value,
                location: document.getElementById('location')?.value,
                nrGuest: document.getElementById('guests')?.value,
                time: document.getElementById('time')?.value,
            };
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://eventsduniya.com/send.php', true);
            xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        y.classList.add("show");
                        setTimeout(function(){ y.classList.remove("show"); }, 3000);
                    } else {
                        console.error('Form submission failed');
                    }
                }
            };

            xhr.send(JSON.stringify(formData));
        };

        contactForm.addEventListener('submit', handleSubmit);
    }
}

if (pageName === 'about' || pageName === 'wedding_style' ||
    pageName === 'poker_night' || pageName === 'corporate_events') {

    const burgerMenu = document.querySelector('.burger-menu');
    const nav = document.querySelector('.mob-side-header');

    if (burgerMenu && nav) {
        burgerMenu.addEventListener('click', () => {
            nav.classList.toggle('open');
        });
    }

    document.addEventListener('DOMContentLoaded', function () {
        new Splide('#slider', {
            perPage: 2,
            height: '200px',
        }).mount();
        new Splide('#slider2', {
            perPage: 1,
            height: '320px',
        }).mount();
    });
}

if (pageName === 'blackjack' || pageName === 'poker' || pageName === 'other_games') {
    const burgerMenu = document.querySelector('.burger-menu');
    const nav = document.querySelector('.mob-side-header');

    if (burgerMenu && nav) {
        burgerMenu.addEventListener('click', () => {
            nav.classList.toggle('open');
        });
    }
}

if (pageName === 'index' || pageName === '') {
    document.addEventListener('DOMContentLoaded', function () {
        new Splide('#slider', {
            perPage: 2,
            height: '200px',
        }).mount();
        new Splide('#slider2', {
            perPage: 1,
            height: '320px',
        }).mount();
    });

    const burgerMenu = document.querySelector('.burger-menu');
    const nav = document.querySelector('.mob-side-header');

    if (burgerMenu && nav) {
        burgerMenu.addEventListener('click', () => {
            nav.classList.toggle('open');
        });
    }

    const faqItems = document.querySelectorAll('.faq_item');
    faqItems.forEach(faqItem => {
        faqItem.addEventListener('click', () => {
            faqItem.classList.toggle('faq_item--active');
        });
    });
    const getCookieKey = localStorage.getItem('show_cookie');
    const cookieWrapper = document.querySelector('.overlay_cookie');
    const cookieBtn = document.querySelector('.yes_cookie');
    const cookieBtnNo = document.querySelector('.no_cookie');
    if (cookieWrapper && cookieBtn) {
        cookieBtn.addEventListener('click', () => {
            cookieWrapper.style.display = "none";
            localStorage.setItem('show_cookie', 'true');
        });
        cookieBtnNo.addEventListener('click', () => {
            cookieWrapper.style.display = "none";
            localStorage.setItem('show_cookie', 'true');
        });

        if (getCookieKey) {
            cookieWrapper.classList.add('hide');
        }
    }
}